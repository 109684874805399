* {
  margin: 0;
  padding: 0;
  font-family: "SpectralCS", sans-serif;
}

.main {
  width: 100%;
  height: 100vh;
  position: relative;
  overflow: hidden;
}

video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.header-link {
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
}

.custom-link {
  font-size: 20px;
  text-decoration: none;
  color: white;
  padding: 10px 20px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  cursor: pointer; /* Ajoute un curseur pointer pour indiquer que c'est cliquable */
}

.custom-link:hover {
  background-color: rgba(
    0,
    0,
    0,
    0.7
  ); /* Change la couleur de fond au survol */
}

.content {
  position: absolute;
  width: 80%;
  height: auto;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: row;
  justify-content: center;
  color: white;
}

.content a {
  font-size: 4vw;
  text-decoration: none;
  color: white;
  margin: 0 15px;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
}

@media (max-width: 768px) {
  .content {
    /* flex-direction: column; */
    align-items: center;
    justify-content: space-between;
    bottom: 10%;
  }

  .content a {
    font-size: 8vw;
    margin: 10px 0;
  }
}
